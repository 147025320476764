@import "reset.css";
@import "overrides.css";

@import "utilities.css";
@import "components.css";
@import "animations.css";

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
