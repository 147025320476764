/* ============================================================
   BOX SIZING >> BORDER BOX
============================================================ */

html {
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* ============================================================
   BODY SIZE
============================================================ */

html,
body,
#root {
    height: 100%;
    width: 100%;
    overflow-y:auto;
}

/* ============================================================
   BODY FIXES
============================================================ */

body {
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

.override-hidden{
  display: none;
}

/* ============================================================
   NUMBER FIELD
============================================================ */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}