.absolutely-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avoid-clicks {
    pointer-events: none;
}

.smooth-scroll {
    scroll-behavior: smooth;
}
